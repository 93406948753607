// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-reports .button-box img{
  max-height: 120px;
  object-fit: contain;
  margin-top: 15px;
}
.test-reports .btn-download {
  position: absolute;
  right: 30px;
  top: 7px;
}
.test-reports .button-box .prescription a{
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/patientprofiles/TestReports.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;AACV;AACA;EACE,UAAU;AACZ","sourcesContent":[".test-reports .button-box img{\r\n  max-height: 120px;\r\n  object-fit: contain;\r\n  margin-top: 15px;\r\n}\r\n.test-reports .btn-download {\r\n  position: absolute;\r\n  right: 30px;\r\n  top: 7px;\r\n}\r\n.test-reports .button-box .prescription a{\r\n  padding: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
