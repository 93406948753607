// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-patient-profiles .form-group,
.form-all .form-group {
  position: relative;
}

.create-patient-profiles .form-group,
.form-all .form-group {
  margin-bottom: 20px;
}

.create-patient-profiles form label,
.form-all form label {
  color: #818181;
  position: absolute;
  padding: 10px;
  font-size: 12px;
  z-index: 1;
}

.form-all form .custom-checkbox label {
  color: #818181;
  position: absolute;
  padding: 0px;
  font-size: 16px;
  z-index: 1;
}

.create-patient-profiles form input,
.form-all form input {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
}

.create-patient-profiles form select,
.form-all form select {
  padding-top: 1.825rem;
  padding-bottom: 0.625rem;
  position: relative;
  appearance: auto;
  color: #818181;
  padding-left: 7px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.font-weight-500{
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/childmalnutrition/CreateChildMalnutrition.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;;AAEA;;EAEE,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;;EAEE,qBAAqB;EACrB,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".create-patient-profiles .form-group,\r\n.form-all .form-group {\r\n  position: relative;\r\n}\r\n\r\n.create-patient-profiles .form-group,\r\n.form-all .form-group {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.create-patient-profiles form label,\r\n.form-all form label {\r\n  color: #818181;\r\n  position: absolute;\r\n  padding: 10px;\r\n  font-size: 12px;\r\n  z-index: 1;\r\n}\r\n\r\n.form-all form .custom-checkbox label {\r\n  color: #818181;\r\n  position: absolute;\r\n  padding: 0px;\r\n  font-size: 16px;\r\n  z-index: 1;\r\n}\r\n\r\n.create-patient-profiles form input,\r\n.form-all form input {\r\n  padding-top: 1.825rem;\r\n  padding-bottom: 0.625rem;\r\n}\r\n\r\n.create-patient-profiles form select,\r\n.form-all form select {\r\n  padding-top: 1.825rem;\r\n  padding-bottom: 0.625rem;\r\n  position: relative;\r\n  appearance: auto;\r\n  color: #818181;\r\n  padding-left: 7px;\r\n}\r\n\r\n.disabled {\r\n  opacity: 0.5;\r\n  cursor: not-allowed;\r\n}\r\n\r\n.font-weight-500{\r\n  font-weight: 500;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
