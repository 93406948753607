// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-category{
  display: flex;
  flex-wrap: wrap;
}
.patient-category .box{
  flex: 1 1;
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  margin: 0 5px;
  min-width: 100px;
  margin-bottom: 20px;
}

.patient-category .box img{
  box-shadow: 0px 0px 4px #e7e7e7;
  border: 1px solid #e5e5e5;
  padding: 5px;
  border-radius: 50%;
}
.type-patient {
  top: 0;
  right: 0;
  border: 1px solid transparent;
  margin: 5px;
  padding: 3px 7px;
  font-size: 10px;
  border-radius: 3px;
  color: #fff;
}
.patientprofiles-booking .button-box img{
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/PatientProfileBooking.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,SAAO;EACP,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,MAAM;EACN,QAAQ;EACR,6BAA6B;EAC7B,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".patient-category{\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n}\r\n.patient-category .box{\r\n  flex: 1;\r\n  text-align: center;\r\n  font-size: 13px;\r\n  line-height: 17px;\r\n  margin: 0 5px;\r\n  min-width: 100px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.patient-category .box img{\r\n  box-shadow: 0px 0px 4px #e7e7e7;\r\n  border: 1px solid #e5e5e5;\r\n  padding: 5px;\r\n  border-radius: 50%;\r\n}\r\n.type-patient {\r\n  top: 0;\r\n  right: 0;\r\n  border: 1px solid transparent;\r\n  margin: 5px;\r\n  padding: 3px 7px;\r\n  font-size: 10px;\r\n  border-radius: 3px;\r\n  color: #fff;\r\n}\r\n.patientprofiles-booking .button-box img{\r\n  margin-top: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
