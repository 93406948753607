// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-listing{
  position: relative;
}
.profile-listing .button-box{
  position: relative;
}

.profile-listing .button-box img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.three-dot{
  position: absolute;
  right: 10px;
  top: 10px;
}

.add-patient{
  text-align: right;
}

.add-patient span{
  font-weight: 600;
}

.profile-listing .button-box .drop-menu{
  top: 28px;
}

.profile-listing .button-box .drop-menu ul{
  padding: 0px 0;
  z-index: 1;
  position: relative;
  background: #fff;
}

.profile-listing .button-box .drop-menu ul li{
  text-align: left;
}

.profile-listing .button-box .drop-menu ul li a{
  padding: 0;
  font-size: 14px;
}
h6.patient-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
p.disease{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.button-box a.btn-box-custom{
  padding: 3px 18px;
  display: inline-block;
}
.booking-patient .button-box img{
  width: 60px;
  height: 60px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/patientprofiles/PatientProfiles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,UAAU;EACV,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".profile-listing{\r\n  position: relative;\r\n}\r\n.profile-listing .button-box{\r\n  position: relative;\r\n}\r\n\r\n.profile-listing .button-box img{\r\n  width: 80px;\r\n  height: 80px;\r\n  border-radius: 50%;\r\n}\r\n\r\n.three-dot{\r\n  position: absolute;\r\n  right: 10px;\r\n  top: 10px;\r\n}\r\n\r\n.add-patient{\r\n  text-align: right;\r\n}\r\n\r\n.add-patient span{\r\n  font-weight: 600;\r\n}\r\n\r\n.profile-listing .button-box .drop-menu{\r\n  top: 28px;\r\n}\r\n\r\n.profile-listing .button-box .drop-menu ul{\r\n  padding: 0px 0;\r\n  z-index: 1;\r\n  position: relative;\r\n  background: #fff;\r\n}\r\n\r\n.profile-listing .button-box .drop-menu ul li{\r\n  text-align: left;\r\n}\r\n\r\n.profile-listing .button-box .drop-menu ul li a{\r\n  padding: 0;\r\n  font-size: 14px;\r\n}\r\nh6.patient-name{\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n}\r\np.disease{\r\n  white-space: nowrap;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n}\r\n.button-box a.btn-box-custom{\r\n  padding: 3px 18px;\r\n  display: inline-block;\r\n}\r\n.booking-patient .button-box img{\r\n  width: 60px;\r\n  height: 60px;\r\n  margin-top: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
