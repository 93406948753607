// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-child-malnutrition label.no-style {
  padding: 0;
  font-size: 16px;
  position: relative;
}
.upload-prescription input[type="file"] {
  display: inline-block;
  font-size: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/childmalnutrition/ChildMalnutrition.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".create-child-malnutrition label.no-style {\r\n  padding: 0;\r\n  font-size: 16px;\r\n  position: relative;\r\n}\r\n.upload-prescription input[type=\"file\"] {\r\n  display: inline-block;\r\n  font-size: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
