// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.app-body{
  padding:25px 15px;
  margin-bottom: 55px;
  margin-top: 70px;
}

.app-body.dashboard svg{
  font-size: 38px;
}

.app-body svg{
  color:#9f9f9f;
}

.red-box{
  color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}
.red-box a{
  color: #fff;
}
.app-body.dashboard .red-box svg{
  color: #fff;
  font-size: 28px;
}

.red-box p{
  font-size: 14px;
  line-height: 18px;
}

ul.list-style li{
  list-style: disc;
}

.banner{
  padding-bottom: 20px;
}

.banner img{
  width: 100%;
  border-radius: 10px;
}

.slick-dots li button:before{
  font-size: 10px!important;
}

.profile-img{
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
}



`, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":";;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["\r\n\r\n.app-body{\r\n  padding:25px 15px;\r\n  margin-bottom: 55px;\r\n  margin-top: 70px;\r\n}\r\n\r\n.app-body.dashboard svg{\r\n  font-size: 38px;\r\n}\r\n\r\n.app-body svg{\r\n  color:#9f9f9f;\r\n}\r\n\r\n.red-box{\r\n  color: #fff;\r\n  border-radius: 10px;\r\n  padding: 15px;\r\n  margin-bottom: 20px;\r\n}\r\n.red-box a{\r\n  color: #fff;\r\n}\r\n.app-body.dashboard .red-box svg{\r\n  color: #fff;\r\n  font-size: 28px;\r\n}\r\n\r\n.red-box p{\r\n  font-size: 14px;\r\n  line-height: 18px;\r\n}\r\n\r\nul.list-style li{\r\n  list-style: disc;\r\n}\r\n\r\n.banner{\r\n  padding-bottom: 20px;\r\n}\r\n\r\n.banner img{\r\n  width: 100%;\r\n  border-radius: 10px;\r\n}\r\n\r\n.slick-dots li button:before{\r\n  font-size: 10px!important;\r\n}\r\n\r\n.profile-img{\r\n  width: 50px;\r\n  height: 50px;\r\n  overflow: hidden;\r\n  border-radius: 50%;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
