// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookings{
  position: relative;
}

.bookings .button-box{
  padding: 10px;
  text-align: left;
}

.bookings .button-box span{
  /* color: #a80707; */
  font-weight: 500;
}

.add-booking{
  text-align: right;
}

.bookings .button-box .drop-menu ul li a {
  padding: 0;
  font-size: 14px;
}

.bookings .my-element2.active + .drop-menu{
  top: 30px;
}

.bookings .button-box a{
  padding: 6px 12px;
  display: inline-block;
}
.advaced-search{
  column-gap: 5px;
}
.advaced-search svg{
  width: 18px;
  fill: #fff;
}
.advaced-search span.search-ultra-plus{
  display: flex;
  align-items: flex-start;
}

.advaced-search span.search-ultra-plus:after {
  content: "+";
  display: block;
  font-size: 9px;
  line-height: 8px;
  margin-left: -3px;
  margin-top: -3px;
  font-family: "Font Awesome 5 Free";
}

.bookings .custom-control-inline{
  margin-right:.5rem;
}
.patient-category .box img{
  max-width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Bookings.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB","sourcesContent":[".bookings{\r\n  position: relative;\r\n}\r\n\r\n.bookings .button-box{\r\n  padding: 10px;\r\n  text-align: left;\r\n}\r\n\r\n.bookings .button-box span{\r\n  /* color: #a80707; */\r\n  font-weight: 500;\r\n}\r\n\r\n.add-booking{\r\n  text-align: right;\r\n}\r\n\r\n.bookings .button-box .drop-menu ul li a {\r\n  padding: 0;\r\n  font-size: 14px;\r\n}\r\n\r\n.bookings .my-element2.active + .drop-menu{\r\n  top: 30px;\r\n}\r\n\r\n.bookings .button-box a{\r\n  padding: 6px 12px;\r\n  display: inline-block;\r\n}\r\n.advaced-search{\r\n  column-gap: 5px;\r\n}\r\n.advaced-search svg{\r\n  width: 18px;\r\n  fill: #fff;\r\n}\r\n.advaced-search span.search-ultra-plus{\r\n  display: flex;\r\n  align-items: flex-start;\r\n}\r\n\r\n.advaced-search span.search-ultra-plus:after {\r\n  content: \"+\";\r\n  display: block;\r\n  font-size: 9px;\r\n  line-height: 8px;\r\n  margin-left: -3px;\r\n  margin-top: -3px;\r\n  font-family: \"Font Awesome 5 Free\";\r\n}\r\n\r\n.bookings .custom-control-inline{\r\n  margin-right:.5rem;\r\n}\r\n.patient-category .box img{\r\n  max-width: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
